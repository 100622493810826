import { RequestSender } from "./helpers";

type ResendOtpLinkInput = {
  readonly emailAddress: string;
};

async function resendOtpLink(
  request: RequestSender,
  { emailAddress }: ResendOtpLinkInput,
): Promise<string> {
  const response = await request<any>("post", "/auth/otp/send-link", {
    sEmail: emailAddress,
  });
  return response.headers.sverificationtoken;
}

export default resendOtpLink;
