import { RequestSender } from "./helpers";

type ClassWalletSessionItem = {
  readonly priceLookupKey: string;
  readonly firstName: string;
  readonly monthOfBirth: string;
  readonly yearOfBirth: string;
  readonly username: string;
  readonly password: string;
};

type AuthAndPopulateClassWalletSessionInput = {
  readonly orderId: string;
  readonly password: string;
  readonly userAuthType: "sign-up" | "sign-in";
  readonly student: ClassWalletSessionItem;
};

async function authAndPopulateClassWalletSessionItems(
  request: RequestSender,
  {
    orderId,
    userAuthType,
    password,
    student,
  }: AuthAndPopulateClassWalletSessionInput,
): Promise<void> {
  const response = await request<any>(
    "put",
    `/class-wallet/order-sessions/${encodeURIComponent(orderId)}/items`,
    {
      userAuthType,
      password,
      student,
    },
  );
  if (response.status !== 204) {
    throw new Error(response.data.message);
  }
}

export type { AuthAndPopulateClassWalletSessionInput };
export default authAndPopulateClassWalletSessionItems;
