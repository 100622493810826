import React, { createContext, ReactNode, useContext, useMemo } from "react";

type ClassWalletValue = {
  readonly vendorId: string;
};

const Context = createContext<ClassWalletValue | undefined>(undefined);

type ClassWalletProviderProps = {
  readonly vendorId: string;
  readonly children: ReactNode;
};

function ClassWalletProvider({ vendorId, children }: ClassWalletProviderProps) {
  const value = useMemo(
    () => ({
      vendorId,
    }),
    [vendorId],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useClassWalletVendorId() {
  const value = useContext(Context);
  if (value === undefined) {
    throw new Error("No class wallet context");
  }

  const { vendorId } = value;
  return vendorId;
}

export { useClassWalletVendorId, ClassWalletProvider };
