import { RequestSender } from "./helpers";

async function exists(request: RequestSender, email: string): Promise<boolean> {
  const response = await request<any>("post", "/auth/user-email", {
    email,
  });
  return response.data.data.exists;
}

export default exists;
