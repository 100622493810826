import { isAxiosErrorWithMessage, RequestSender } from "./helpers";
import { EmailLoginResponse } from "./model";

function isUserNotFoundError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 404, "user not found");
}

function isIncorrectPasswordError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 403, "Wrong credentials");
}

async function login(
  request: RequestSender,
  username: string,
  password: string,
): Promise<EmailLoginResponse> {
  try {
    const response = await request<any>("post", "/auth/login", {
      sEmail: username,
      sPassword: password,
    });
    return {
      id: response.data.data.id,
      username: response.data.data.sUserName,
      emailAddress: response.data.data.sEmail,
      userType: response.data.data.eUserType,
      loginType: {
        type: response.data.data.oLoginType.eType,
      },
      authToken: response.headers.authorization,
    };
  } catch (error) {
    if (isUserNotFoundError(error) || isIncorrectPasswordError(error)) {
      throw new Error("Please check your email and password");
    }
    throw error;
  }
}

export { isUserNotFoundError, isIncorrectPasswordError };
export default login;
