import React from "react"
import { setLocale } from "yup";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "~/hooks/appContext";
import Layout from '~/features/marketing/Layout';
import ensureEnv from '~/utils/ensureEnv';
import "react-toastify/dist/ReactToastify.css";

setLocale({
  mixed: {
    required: "Required",
  },
  string: {
    email: "Invalid email address",
  },
});

function wrapRootElement({ element }) {
  return (
    <AppProvider
      stripeKey={ensureEnv("GATSBY_STRIPE_KEY", process.env.GATSBY_STRIPE_KEY)}
      cleverClientId={ensureEnv("GATSBY_CLEVER_CLIENT_ID", process.env.GATSBY_CLEVER_CLIENT_ID)}
      googleClientId={ensureEnv("GATSBY_GOOGLE_CLIENT_ID", process.env.GATSBY_GOOGLE_CLIENT_ID)}
      classWalletVendorId={ensureEnv("GATSBY_CLASS_WALLET_VENDOR_ID", process.env.GATSBY_CLASS_WALLET_VENDOR_ID)}
      apiBaseUrl="https://api.roundedlearning.com/api/v1"
    >
      {element}
    </AppProvider>
  );
}

function wrapPageElement({ element }) {
  return (
    <Layout>
      {element}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </Layout>
  );
}

export { wrapPageElement, wrapRootElement }
