exports.components = {
  "component---src-features-marketing-faq-category-page-tsx": () => import("./../../../src/features/marketing/FaqCategoryPage.tsx" /* webpackChunkName: "component---src-features-marketing-faq-category-page-tsx" */),
  "component---src-features-marketing-research-article-page-tsx": () => import("./../../../src/features/marketing/ResearchArticlePage.tsx" /* webpackChunkName: "component---src-features-marketing-research-article-page-tsx" */),
  "component---src-features-marketing-research-category-page-tsx": () => import("./../../../src/features/marketing/ResearchCategoryPage.tsx" /* webpackChunkName: "component---src-features-marketing-research-category-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-get-started-class-wallet-learn-more-tsx": () => import("./../../../src/pages/get-started/class-wallet-learn-more.tsx" /* webpackChunkName: "component---src-pages-get-started-class-wallet-learn-more-tsx" */),
  "component---src-pages-get-started-classrooms-learn-more-tsx": () => import("./../../../src/pages/get-started/classrooms-learn-more.tsx" /* webpackChunkName: "component---src-pages-get-started-classrooms-learn-more-tsx" */),
  "component---src-pages-get-started-homes-learn-more-tsx": () => import("./../../../src/pages/get-started/homes-learn-more.tsx" /* webpackChunkName: "component---src-pages-get-started-homes-learn-more-tsx" */),
  "component---src-pages-get-started-index-tsx": () => import("./../../../src/pages/get-started/index.tsx" /* webpackChunkName: "component---src-pages-get-started-index-tsx" */),
  "component---src-pages-get-started-schools-learn-more-tsx": () => import("./../../../src/pages/get-started/schools-learn-more.tsx" /* webpackChunkName: "component---src-pages-get-started-schools-learn-more-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-to-state-standards-tsx": () => import("./../../../src/pages/map-to-state-standards.tsx" /* webpackChunkName: "component---src-pages-map-to-state-standards-tsx" */),
  "component---src-pages-oauth-clever-tsx": () => import("./../../../src/pages/oauth/clever.tsx" /* webpackChunkName: "component---src-pages-oauth-clever-tsx" */),
  "component---src-pages-oauth-google-tsx": () => import("./../../../src/pages/oauth/google.tsx" /* webpackChunkName: "component---src-pages-oauth-google-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-problemscape-ch-1-overview-tsx": () => import("./../../../src/pages/problemscape/ch-1-overview.tsx" /* webpackChunkName: "component---src-pages-problemscape-ch-1-overview-tsx" */),
  "component---src-pages-problemscape-ch-2-overview-tsx": () => import("./../../../src/pages/problemscape/ch-2-overview.tsx" /* webpackChunkName: "component---src-pages-problemscape-ch-2-overview-tsx" */),
  "component---src-pages-problemscape-ch-3-overview-tsx": () => import("./../../../src/pages/problemscape/ch-3-overview.tsx" /* webpackChunkName: "component---src-pages-problemscape-ch-3-overview-tsx" */),
  "component---src-pages-problemscape-ch-4-overview-tsx": () => import("./../../../src/pages/problemscape/ch-4-overview.tsx" /* webpackChunkName: "component---src-pages-problemscape-ch-4-overview-tsx" */),
  "component---src-pages-problemscape-xpert-ypert-tsx": () => import("./../../../src/pages/problemscape/xpert-ypert.tsx" /* webpackChunkName: "component---src-pages-problemscape-xpert-ypert-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-sama-tsx": () => import("./../../../src/pages/sama.tsx" /* webpackChunkName: "component---src-pages-sama-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-stepbystep-tsx": () => import("./../../../src/pages/stepbystep.tsx" /* webpackChunkName: "component---src-pages-stepbystep-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-verify-signup-tsx": () => import("./../../../src/pages/verify-signup.tsx" /* webpackChunkName: "component---src-pages-verify-signup-tsx" */)
}

