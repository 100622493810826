import React, { ReactNode } from "react";
import { AuthProvider } from "~/hooks/authContext";
import { ApiProvider } from "~/hooks/apiContext";
import { StripeProvider } from "~/hooks/stripeContext";
import { ClassWalletProvider } from "./classWalletContext";

type AppProviderProps = {
  readonly apiBaseUrl: string;
  readonly stripeKey: string;
  readonly cleverClientId: string;
  readonly googleClientId: string;
  readonly classWalletVendorId: string;
  readonly children: ReactNode;
};

function AppProvider({
  apiBaseUrl,
  googleClientId,
  cleverClientId,
  stripeKey,
  classWalletVendorId,
  children,
}: AppProviderProps) {
  return (
    <StripeProvider stripeKey={stripeKey}>
      <ClassWalletProvider vendorId={classWalletVendorId}>
        <AuthProvider
          cleverClientId={cleverClientId}
          googleClientId={googleClientId}
        >
          <ApiProvider baseUrl={apiBaseUrl}>{children}</ApiProvider>
        </AuthProvider>
      </ClassWalletProvider>
    </StripeProvider>
  );
}

export { AppProvider };
