import { isAxiosErrorWithMessage, RequestSender } from "./helpers";

function isAccountInactiveError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 403, "Account inactive");
}

function isEmailAddressExistsError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 409, "User already exists");
}

async function signUp(
  request: RequestSender,
  username: string,
  password: string,
  userType: "parent" | "teacher",
  orderId: string | undefined,
): Promise<string> {
  const response = await request<any>("post", "/auth/register", {
    sEmail: username,
    sPassword: password,
    sNewRetypedPassword: password,
    eUserType: userType,
    orderId,
  });
  return response.headers.sverificationtoken;
}

export { isAccountInactiveError, isEmailAddressExistsError };
export default signUp;
